import { Container } from '@krakentech/coral';

import { NotFoundError } from '@/components';

import CustomHead from './_head.page';

export default function NotFound() {
  return (
    <Container
      component="section"
      marginBottom="lg"
      marginX="auto"
      padding="md"
      paddingY="xxl"
    >
      <CustomHead title="Page not found" />
      <NotFoundError />
    </Container>
  );
}
